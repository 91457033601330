.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  position: relative;
}

.hidden {
  visibility: hidden;
}
