$enable-gradients: true;
$enable-shadows: true;
$blue: #203264;
$red: #a41725;
$orange: #fd5714;
$yellow: #e39900;
$green: #1d7935;
$dark: #221d24;

$gray-100: #f0f0f0;
$gray-600: #6c4775;
$gray-800: #1c2b3b;

$border-radius-lg: .75rem;
$border-radius: .5rem;
$borer-radius-sm: .1rem;

$primary: $blue;
$success: $green;
$warning: $yellow;
$danger: $red;

$link-color: $primary;

@import 'bootstrap/scss/bootstrap';
