@import 'bootstrap';

.pageTitle {
  padding: 15px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $input-border-color;
  margin-bottom: $form-group-margin-bottom;

  h3 {
    margin: 0;
    line-height: 1;
  }

  + table thead th,
  + div > table thead th {
    border-top: 0;
  }
}

.buttons {
  margin-left: 1rem;

  button {
    font-size: 0.7rem;
  }
}
