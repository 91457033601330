@import 'scss/bootstrap';

$rt-color-dark: $gray-800;
$rt-color-info: $info;
$rt-color-success: $success;
$rt-color-warning: $warning;
$rt-color-error: $danger;

@import 'react-toastify/scss/main';

body {
  margin-bottom : 5rem !important;

  a {
    color: $link-color;
  }
}

footer {
  a {
    color: #666;
    text-decoration: underline;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
