@import 'bootstrap';

.fileUploader {
  display: flex;
  width: 100%;

  input[type="file"] {
    position: absolute;
    left: -999rem;
    top: -999rem;
  }
}

.filePreview {
  width: 100%;
  background: transparent;
  text-align: left;
  border: 1px solid $input-border-color;
  border-top-left-radius: $input-border-radius;
  border-bottom-left-radius: $input-border-radius;
  box-shadow: $input-box-shadow;
  direction: rtl;

  @include text-truncate();

  .placeholder {
    color: $input-placeholder-color;
  }

  + button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
